.transcriptEditor {
  color: black;
  line-height: 1.3;
  font-size: 1rem;
  font-weight: 400;
  outline: none;
  position: relative;
  max-width: 1000px;
  margin-right: 5rem;

  &-row {
    padding: 0.35rem 0;

    &:nth-child(odd) {
      background-color: rgb(248, 248, 248);
    }

    &:nth-child(even) {
      background-color: rgb(255, 255, 255);
    }
  }

  &-row & span[data-conf="low"] {
    color: #b46100;
  }

  &-title {
    padding-bottom: 0.1rem;
    padding-left: 2rem;
    font-size: 1.15rem;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-speaker {
      font-weight: 500;
      color: @primary-color;
      margin-right: 1rem;
      cursor: pointer;
    }
    &-time {
      cursor: pointer;
      font-weight: 500;
    }
  }
  .popup-show {
    display: block;
  }
  &-toolbar {
    --space-between: 0.3rem;
    position: absolute;
    z-index: 1;
    padding: var(--space-between);
    padding-left: 0;
    background-color: @primary-color;
    border-radius: 0.25rem;
    top: -10000px;
    left: -10000px;
    opacity: 0;
    transition: opacity 0.75s;

    > button {
      margin-left: var(--space-between);
      background-color: transparent;
      border: none;
      color: white;
    }

    > button:hover {
      background-color: white;
    }
  }
  &-popup {
    --space-between: 0.3rem;
    position: absolute;
    display: none;
    user-select: none;
    padding: var(--space-between);
    padding-left: 0;
    background-color: @primary-color;
    border-radius: 0.25rem;

    button {
      margin-left: var(--space-between);
      background-color: transparent;
      border: none;
      color: white;
    }

    button:hover {
      background-color: white;
    }
  }
  &-editor {
    // word-wrap: break-word;
    padding-left: 5rem;
    position: relative;
    line-height: 1.5;
    // white-space: pre;
    overflow-wrap: break-word;
    word-break: normal;
    line-break: auto;
    -webkit-line-break: after-white-space;

    & .highlight {
      background-color: yellow;
    }

    & .current {
      background-color: rgb(39, 89, 196) !important;
      color: white !important;
    }

    & .changed {
      color: #00a13e;
    }

    & .normal {
      color: black;
    }

    & .low-conf {
      color: #b46100;
    }

    & .join {
      color: #b00000;
    }

    & .hover:hover {
      background-color: red;
      color: white;
    }
  }
}
.find-replace-modal {
  width: 35rem !important;
}

@primary-color: #4a90e2;
@color-primary-default: #4675c0;
@color-primary-lighter: #8fc8eb;
@color-primary-transparent: rgba(39, 103, 120, 0.664);
@color-primary-dark: #19335a;
@color-light-default: #d3e0ea;
@color-light-lighter: #f6f5f5;
// COLORS

@color-grey-dark: #697a98;
@color-grey-dark-2: #b8bfd6;
@color-grey-dark-3: #333;
@color-grey-dark-transparent: rgba(51, 51, 51, 0.15);

@color-white: #fff;
@color-white-transparent: rgba(255, 255, 255, 0.9);
@color-white-transparent-more: rgba(255, 255, 255, 0.6);

@color-black: #000;

@color-google: #ff4359;
@color-facebook: #0078ff;
// FONT
@default-font-size: 1.6rem;

//screen size
@XS: 480px;
@SM: 768px;
@MD: 1024px;
@XL: 1170px;

// Speaking status height
@speaking-status-height: 2rem;

.keyword-panel {
  &-tag {
    padding: 2px 14px;
    border: 1px rgba(111, 111, 111, 0.2) solid;
    border-radius: 4px;
    max-width: 30%;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }

  &-header {
    .ant-collapse-header-text {
      flex: 1;
    }
    .ant-collapse-header {
      background-color: white;
    }
    .ant-collapse-content {
      background-color: unset;
    }
    .ant-collapse-content-box {
      padding: 0px 12px 12px 12px;
    }
  }
}

.edit {
  height: calc(100vh - 64px);
  width: 100vw;
  overflow-x: hidden;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;

  &-header {
    height: 4rem;
    padding-left: 18px;

    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding-block: 10px;

      .warning {
        color: #ff4359;
      }

      &-warning {
        padding: 0;
        border: none;

        .link {
          color: @primary-color;
          text-decoration: underline;
        }
      }
    }
  }
  &-row {
    height: 100%;
    overflow-y: hidden;
  }
  &-rightcol {
    height: 100%;
    overflow-y: auto;
    padding-right: 0;
    box-shadow: inset 0px 1px 1px 1px rgba(136, 136, 136, 0.1);
  }

  &-rightcol::-webkit-scrollbar {
    width: 0;
  }

  &-leftcol {
    height: 100%;
    overflow-y: scroll;
    padding-bottom: @speaking-status-height;
    // padding: 0 2rem;
  }
  &-video {
    &-player {
      width: 100%;
    }

    canvas {
      width: 100%;
      height: 300px;
    }

    &-progress {
      margin: 1rem 0;
      padding: 0 1rem;
      &-slider {
        margin: 0.5rem 0 !important;
      }
      & progress {
        width: 100%;
      }
      & input {
        width: 100%;
      }
    }
    &-btn {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-columns: 1fr;
      align-items: center;
      justify-items: center;
      &-item {
        border-top: 1px solid @color-grey-dark-transparent;
        border-right: 1px solid @color-grey-dark-transparent;
        border-bottom: 1px solid @color-grey-dark-transparent;
        color: @color-grey-dark;
        width: 100%;
        padding: 1rem 0;
        text-align: center;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        transition: all 0.3s ease-out;
        & .anticon {
          transition: all 0.3s ease-out;
        }
        &:hover {
          background-color: @primary-color;
          & .edit-video-btn-item-icon .anticon {
            transform: scale(1.4);
            color: @color-white;
          }
          & .edit-video-btn-item-text {
            color: @color-white;
          }
        }
        &-icon {
          color: #555;
          font-size: 32px;
          transition: all 0.5s ease-in;
        }
      }
    }

    &-keyword {
      width: 100%;
      padding-block: 10px;

      > label {
        font-weight: 500;
        margin-left: 11px;
        letter-spacing: 2px;
      }
    }

    &-note {
      width: 100%;
      padding-block: 10px;

      & textarea {
        overflow: auto;
        background: unset;
        border: none;
        min-height: 12rem !important;
        resize: none;
      }
      & textarea:focus {
        box-shadow: none;
      }
      & label span {
        font-weight: 500;
        margin-left: 11px;
        letter-spacing: 2px;
      }
    }
  }
  &-dictionary {
    width: 50vw !important;
    //   @include mediumScreen {
    //     width: 70vw !important;
    //   }
    //   @include smallerMedium {
    //     width: 100vw !important;
    //   }
    &-actions {
      display: flex;
      align-items: center;
    }
  }
}
.jumpcontent {
  display: grid !important;
  place-items: center;
}

.navigate-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 30px;
  right: 30px;

  button:first-child {
    margin-bottom: 5px;
  }

  button {
    opacity: 0.2;

    &:hover {
      opacity: 1;
    }
  }
}

.speaking-status {
  position: fixed;
  bottom: 0;
  left: 0;
  height: @speaking-status-height;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgb(248, 248, 248);
  padding-left: calc(@speaking-status-height / 2);
  font-weight: 500;

  @keyframes quiet {
    25% {
      transform: scaleY(0.6);
    }
    50% {
      transform: scaleY(0.4);
    }
    75% {
      transform: scaleY(0.8);
    }
  }
  @keyframes normal {
    25% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0.4);
    }
    75% {
      transform: scaleY(0.6);
    }
  }
  @keyframes loud {
    25% {
      transform: scaleY(1);
    }
    50% {
      transform: scaleY(0.4);
    }
    75% {
      transform: scaleY(1.2);
    }
  }

  &-icon {
    color: #909090;
    height: 90%;
    margin-right: 0.3rem;
  }

  .wave-container {
    display: flex;
    justify-content: space-between;
    margin-left: 0.5rem;
    height: 60%;
    --boxSize: 3px;
    --gutter: 2px;
    width: calc(var(--boxSize) * 5 + var(--gutter) * 4);

    .line {
      transform: scaleY(0.4);
      height: 100%;
      width: var(--boxSize);
      background: #909090;
      animation-duration: 1.2s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      border-radius: 10px;
    }

    .line1 {
      animation-name: quiet;
    }

    .line2 {
      animation-name: normal;
    }

    .line3 {
      animation-name: quiet;
    }

    .line4 {
      animation-name: loud;
    }

    .line5 {
      animation-name: quiet;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

textarea:focus,
input:focus {
  outline: none;
}

.safari-modal {
  max-height: 50vh;
  overflow-y: scroll;

  img {
    margin-bottom: 15px;
  }
}

.jump-content {
  &-container {
    display: flex;
    border-radius: 5px;
    border: 1px solid rgba(51, 51, 51, 0.15);
    height: 100%;

    button {
      border: 0px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

      p {
        margin: 0;
      }
    }
  }

  &-input-container {
    width: 70px;
    height: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid rgba(51, 51, 51, 0.15);

    p {
      text-align: center;
      margin-bottom: 6px;
    }

    input {
      border: 0px;
      width: 24px;
      height: fit;
      font-size: 16px;
      text-align: center;
    }
  }
}

.color-picker {
  &-button {
    margin-left: 12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    border: 1px rgba(111, 111, 111, 0.4) solid;
    flex-shrink: 0;
  }
}

.keyword-tag {
  user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  padding: 3px 16px;
  margin-right: 8px;
  min-width: 20px;
  height: 2em;
}

.keyword-edit {
  &-overlay {
    .ant-popover-inner-content {
      padding: 0;
    }
  }

  &-form {
    padding: 12px 16px;
  }
}

@primary-color: #4a90e2;